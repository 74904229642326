@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom Tippy theme */
.tippy-box[data-theme~='light'] {
    background-color: rgba(0, 0, 0, 0.75); /* Dark background for readability */
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
}
